import React, { Component } from 'react';
import ImageLazyLoad from './ImageLazyLoad.js';
// import Plx from 'react-plx';

class BonusDays extends Component {
  
  render() {

    // const sectionIntroNoFade = [
    //   {
    //     start: 'self',
    //     startOffset: 200,
    //     duration: 300,
    //     properties: [
    //       {
    //         startValue: 200,
    //         endValue: 0,
    //         property: "translateY"
    //       }
    //     ]
    //   }
    // ];

    return (

      <div>

        <section
          className="
            flex flex-wrap
            py-4 xs:py-8 sm:py-16 md:py-24
            px-4 xs:px-8 sm:px-16 md:px-24
            bg-yellow
        ">

          <header className="
            flex flex-wrap w-full
            px-4
            mb-8
            text-lg">
            <h2 className="w-full font-bold">Bonus Days 9-12 (In Limbo)</h2>
            <em>Sunday 13th — Wednesday 16th October</em>
          </header>

          <figure className="
            w-full
            px-4
            mb-8
            filter-grayscale mix-multiply">
            <ImageLazyLoad
              src={"./photos/DSCF7592.jpg"}
              alt={"A dog on a leash in Asakusa"}
              widthLg={"2600"}
              widthSm={"1200"}
              ratioW={"3"}
              ratioH={"2"}
              noFade={true}
            />
          </figure>

          <div className="w-full lg:w-3/4
                          mb-8
                          px-4
                          md:col-count-2 xxl:col-count-3 col-gap-lg">
            <p>The morning following the typhoon, I wake up to a perfect sunny day - still a bit windy, but Tokyo is apparently completely dry after the heaviest 24 hours of rain I have ever seen. I suspect this is in large part due to the <a href="https://www.nytimes.com/2017/10/06/climate/tokyo-floods.html" title="NY Times article about the storm drain">Mega Storm Drain</a>, built in 2006.</p>
            <p>One unexpected positive side-effect of the delayed travel (other than 4 more days in Tokyo! after much back and forth with travel agents) is that I'm now able to attend a “Drop-in” <a href="https://www.festival-tokyo.jp/19/en/program/hand-saw-press.html">Riso zine studio</a> run by Hand Saw Press, as part of Festival/Tokyo.</p>
            <p>This is my first time Riso printing (for the uninitiated: it's essentially like screen printing one layer of colour at a time, in a photo-copier like machine). Ryoko and Shinsuke are really welcoming and helpful, many thanks! Luckily I spent some time preparing the graphics for print earlier in the day so the process isn't too painful (I hope, for them).</p>
            <p>We manage to print about 60 A3 copies which I eventually fold up into a mini-zine back in Oslo (thanks for the help, João!). It's an imperfect printing process, but I'm happy with the results.</p>
            
          </div>

          <figure className="
            w-full lg:w-1/4
            px-16 sm:px-32 md:px-48 lg:px-4 xxxl:px-24
            mb-8
            mix-multiply">
            <ImageLazyLoad
              src={"./drawings/day-12.jpg"}
              alt={"A drawing of a lady falling asleep on the metro"}
              widthLg={"1600"}
              widthSm={"800"}
              ratioW={"232"}
              ratioH={"251"}
              noFade={true}
            />
          </figure>

          <div className="w-1/2 px-4 mb-4 filter-grayscale mix-multiply">
            <figure className="w-full mb-4">
              <ImageLazyLoad
                src={"./photos/DSCF7687.jpg"}
                alt={"Hand Saw Press"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"2"}
                ratioH={"3"}
                noFade={true}
              />
            </figure>
          </div>

          <div className="w-1/2 filter-grayscale mix-multiply">
          
            <figure className="mb-4">
              <ImageLazyLoad
                src={"./photos/DSCF7707.jpg"}
                alt={"Printing at Hand Saw Press"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"3"}
                ratioH={"2"}
                noFade={true}
              />
            </figure>

            <figure className="">
              <ImageLazyLoad
                src={"./photos/DSCF7715.jpg"}
                alt={"Ryoko and Shinsuke from Hand Saw Press"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"3"}
                ratioH={"2"}
                noFade={true}
              />
            </figure>
            
          </div>

        </section>

        <section>

          <div className="flex flex-wrap items-stretch">
            
            <div className="w-full md:w-1/2 bg-black">
              <figure className="">
                <ImageLazyLoad
                  src={"./photos/DSCF7798.jpg"}
                  alt={"Zoomorphic utility boxes of Tokyo zines from Handsaw Press 1"}
                  widthLg={"2600"}
                  widthSm={"1200"}
                  ratioW={"3"}
                  ratioH={"2"}
                />
              </figure>
            </div>

            <div className="w-full md:w-1/2 bg-black">
              <figure className="">
                <ImageLazyLoad
                  src={"./photos/DSCF7806.jpg"}
                  alt={"Zoomorphic utility boxes of Tokyo zines from Handsaw Press 2"}
                  widthLg={"2600"}
                  widthSm={"1200"}
                  ratioW={"3"}
                  ratioH={"2"}
                />
              </figure>
            </div>

            {/* <figure className="w-1/3">
              <Image
                srcSet="./photos/DSCF7803.jpg?nf_resize=fit&w=1200 800w,
                        ./photos/DSCF7803.jpg?nf_resize=fit&w=2600 1800w"
                src="./photos/DSCF7803.jpg?nf_resize=fit&w=2600"
                alt="Zoomorphic utility boxes of Tokyo zines from Handsaw Press"
                placeholderColor="#fcfcfc"
              />
              <figcaption></figcaption>
            </figure> */}
          
            <figure className="w-full">
              <ImageLazyLoad
                src={"./photos/DSCF7818.jpg"}
                alt={"Zoomorphic utility boxes of Tokyo zines from Handsaw Press 3"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </figure>
            
          </div>

        </section>
        
      </div>
    )
  }
}
export default BonusDays;