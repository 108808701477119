// Kuramae / Yanaka / Nezu
import React, { Component } from 'react';
import Image from "react-graceful-image";
import ImageLazyLoad from './ImageLazyLoad.js';
import Plx from 'react-plx';

class Day2 extends Component {
  render() {

    const sectionIntro = [
      {
        start: 'self',
        startOffset: 0,
        duration: 500,
        easing: "easeOutQuad",
        properties: [
          {
            startValue: 300,
            endValue: 0,
            property: "translateY"
          },
          {
            startValue: 0,
            endValue: 1,
            property: "opacity"
          }
        ]
      }
    ];

    const circle1 = [
      {
        start: 'self',
        duration: 2000,
        properties: [
          {
            startValue: -5,
            endValue: 10,
            property: "rotate",
            unit: "deg"
          }
        ]
      }
    ];
    const circle1Text = [
      {
        start: 'self',
        duration: 3000,
        properties: [
          {
            startValue: 0,
            endValue: -80,
            property: "rotate",
            unit: "deg"
          }
        ]
      }
    ];

    const circle2 = [
      {
        start: 'self',
        duration: 2000,
        properties: [
          {
            startValue: -10,
            endValue: 5,
            property: "rotate",
            unit: "deg"
          }
        ]
      }
    ];

    const circle2Text = [
      {
        start: 'self',
        duration: 3000,
        properties: [
          {
            startValue: 60,
            endValue: -40,
            property: "rotate",
            unit: "deg"
          }
        ]
      }
    ];

    return (

      <div
        id="day-2"
        className="pb-16 md:pb-32">

        <section
          className="
            flex flex-wrap
            mb-16 md:mb-32
            px:8 xs:px-16 sm:px-16 md:px-24"
        >
          <Plx parallaxData={ sectionIntro }>

            <header className=" w-full
                                px-4
                                text-lg">
              <h2 className="font-bold">Day 2</h2>
              <em>Sunday, 06 October</em>
            </header>

            <div className=" flex flex-wrap items-start
                                w-full
                                mb-16 md:mb-32  ">

              <figure className="w-1/2 p-4">
                <ImageLazyLoad
                  src={"./photos/DSCF6970.jpg"}
                  alt={"A car-parking turntable and clean garage in Asakusa"}
                  widthLg={"2600"}
                  widthSm={"1200"}
                  ratioW={"3"}
                  ratioH={"2"}
                />
              </figure>

              <figure className="w-1/2 p-4">
                <ImageLazyLoad
                  src={"./photos/DSCF6992.jpg"}
                  alt={"A messy garage with car stuffed into a half closed garage"}
                  widthLg={"2600"}
                  widthSm={"1200"}
                  ratioW={"3"}
                  ratioH={"2"}
                  noFade={true}
                />
              </figure>

              <figcaption className=" w-full p-4
                                      sm:col-count-1 md:col-count-2 xl:col-count-4 col-gap-lg">
                <p>Feeling rested after the <em>longest day</em> yesterday, I take to the streets of Taito, walking from the hotel in Ueno down to Asakusa-Kuramae.</p>
                <p>Every street is endlessly interesting to me and I have difficulties walking more than ten metres at a time without taking a photo of some interesting detail.</p>
                <p>What strikes me is the contrast between immaculately designed solutions like the turntables for parking cars that can be found on most streets, and the alternative of just cramming stuff so it fits (or not – in this, and many other cases) into impossible spaces.</p>
              </figcaption>
              
              <figure className="w-full p-4">
                <ImageLazyLoad
                  src={"./photos/DSCF7558.jpg"}
                  alt={"A van crammed into a garage"}
                  widthLg={"2600"}
                  widthSm={"1200"}
                  ratioW={"3"}
                  ratioH={"2"}
                />
              </figure>
            </div>
          
          </Plx>

          <div className="w-full
                          px-4 lg:px-16 xl:px-32 xxl:px-48 xxxl:px-64
                          mb-16">

            <figure className="px-4 lg:px-16 mb-8">
              <ImageLazyLoad
                src={"./drawings/day-2-1.jpg"}
                alt={"A van crammed into a garage"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"460"}
                ratioH={"185"}
              />
            </figure>

            <div className="px-4
                            mb-16
                            sm:col-count-1 md:col-count-2 col-gap-lg">
              <p><a href="https://goo.gl/maps/TQjXJ5aRESwaBjxZ8" title="Coffee Wrights">Coffee Wrights</a> in Kuramae doesn't have a spectacular view, but I take the opportunity to enjoy a coffee and sketch some interesting architectural details from across the street.</p>
              <p>This is one example of how detailed and thought through everything seems to be at street level — all the tiny tiles must take forever to apply, but they are everywhere you look.</p>
              <p>I'll be reminded of this later in the day when seeing ancient stone tablets perfectly divided into tiny squares at the Taito <a href="http://www.taitocity.net/zaidan/shodou/" title="Calligraphy museum website">calligraphy museum</a>. I'm not sure I recommend the museum for non-Japanese readers; the contents are quite <em>dry</em>.</p>
            </div>

          </div>

          <div className="
            relative
            w-full
            flex flex-wrap
            mb-8 md:mb-16 lg:mb-32
            px-4 
            pb-4
            overflow-hidden">

            <div className="
              relative
              w-full md:w-1/2">
              
              <figure className="relative pb-full rounded-full overflow-hidden scale-90pc">
                <Plx
                  className='absolute inset-0'
                  parallaxData={ circle1 }
                >
                  <Image
                    className="absolute inset-0 object-cover object-right h-full no-fade"
                    srcSet="./photos/DSCF6998.jpg?nf_resize=fit&w=800 800w,
                            ./photos/DSCF6998.jpg?nf_resize=fit&w=1600 1600w"
                    src="./photos/DSCF6998.jpg?nf_resize=fit&w=1600"
                    alt="Moped guy 1"
                    placeholderColor="#fcfcfc"
                  />
                </Plx>
              </figure>

              <Plx
                  className='absolute inset-0'
                  parallaxData={ circle1Text }
                >
                <svg 
                  viewBox="0 0 400 400"
                  className=" absolute  inset-0
                              z-10">
                  <text x="0" y ="0%">
                    <textPath
                      xlinkHref="#roundrect1"
                      startOffset="850"
                      fill="black"
                      className="text-sm"
                      textAnchor="start">
                      The first time I saw this guy, in Asakusa-Kuramae.
                    </textPath>
                  </text>
                  <path id="roundrect1" fill="transparent" d="M200,400c110.5,0,200-89.5,200-200S310.5,0,200,0S0,89.5,0,200S89.5,400,200,400"/>
                </svg>
              </Plx>
              
            </div>

            <div className="relative
                            w-full md:w-1/2">

              <figure className="relative pb-full rounded-full overflow-hidden scale-90pc">
                <Plx
                  className='absolute inset-0'
                  parallaxData={ circle2 }
                >
                  <Image
                    className="absolute inset-0 object-cover object-right h-full no-fade"
                    srcSet="./photos/DSCF7003.jpg?nf_resize=fit&w=800 800w,
                            ./photos/DSCF7003.jpg?nf_resize=fit&w=1600 1600w"
                    src="./photos/DSCF7003.jpg?nf_resize=fit&w=1600"
                    alt="Moped guy 2"
                    placeholderColor="#fcfcfc"
                  />
                </Plx>
              </figure>

              <Plx
                className='absolute inset-0'
                parallaxData={ circle2Text }
              >
                <svg 
                  viewBox="0 0 400 400"
                  className=" absolute inset-0
                              z-10">
                  <path id="roundrect1" fill="transparent" d="M0,200c0,110.5,89.5,200,200,200s200-89.5,200-200S310.5,0,200,0S0,89.5,0,200z"/>
                  <text x="0" y ="0%">
                    <textPath
                      xlinkHref="#roundrect1"
                      startOffset="0"
                      fill="black"
                      className="text-sm"
                      textAnchor="start"
                      path="M0,200c0,110.5,89.5,200,200,200s200-89.5,200-200S310.5,0,200,0S0,89.5,0,200z">
                      Five minutes later on the other side of the street: weird.
                    </textPath>
                  </text>
                </svg>
              </Plx>

            </div>
          
          </div>

          <div className="
            flex justify-center flex-col items-center
            px-4">

            <figure className="
              w-full md:w-2/3 lg:w-1/2
              px-4 mb-8">
              <ImageLazyLoad
                src={"./drawings/day-2-2.jpg"}
                alt={"Drawings of restaurant interiors"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"460"}
                ratioH={"297"}
              />
            </figure>
            
            <div className="w-full md:w-2/3 lg:w-1/2
              px-4">
              <p>Wanting to sketch as much as possible I start to draw a couple of restaurant interiors but soon realise that service in general is too quick and space too limited to be able to settle down and concentrate on drawing for an extended period of time.</p>
            </div>

          </div>

        </section>
        

        <section className="flex flex-wrap
                            items-start
                            px-4 pt-8
                            bg-black">

          <figure className="w-full px-4">
            <ImageLazyLoad
              src={"./photos/DSCF7070.jpg"}
              alt={"Backstreets of Nezu 1"}
              widthLg={"2600"}
              widthSm={"1200"}
              ratioW={"3"}
              ratioH={"2"}
            />
          </figure>

          <figcaption className="w-full px-4 text-center md:text-justify text-white">
            The back streets of Yanaka and Nezu
            <span className="w-full inline-block h-0 invisible"></span> {/* text justify hack */}
          </figcaption>

          <figure className=" w-full md:w-1/2
                              -mt-6 md:-mt-8 mb-8 md:mb-0
                              px-4">
            <ImageLazyLoad
              src={"./photos/DSCF7060.jpg"}
              alt={"Backstreets of Nezu 2"}
              widthLg={"2600"}
              widthSm={"1200"}
              ratioW={"3"}
              ratioH={"2"}
            />
          </figure>

          <figure className=" w-full md:w-1/2
                              md:-mt-8
                              px-4">
            <ImageLazyLoad
              src={"./photos/DSCF7047.jpg"}
              alt={"Backstreets of Nezu 3"}
              widthLg={"2600"}
              widthSm={"1200"}
              ratioW={"3"}
              ratioH={"2"}
            />
            {/* <Image
              srcSet="./photos/DSCF7046.jpg?nf_resize=fit&w=1200 1200w,
                      ./photos/DSCF7046.jpg?nf_resize=fit&w=2600 2600w"
              src="./photos/DSCF7046.jpg?nf_resize=fit&w=2600"
              alt="Backstreets of Nezu"
              placeholderColor="#fcfcfc"
            /> */}
            
          </figure>

          <figcaption className="w-full px-4 text-center md:text-justify text-white">
            are particularly atmospheric at night-time.
            <span className="w-full inline-block h-0 invisible"></span>{/* text justify hack */}
          </figcaption>

          <figure className=" w-full md:w-1/2
                              -mt-6 md:-mt-8 mb-8
                              px-4">
            <ImageLazyLoad
              src={"./photos/DSCF7050.jpg"}
              alt={"Backstreets of Nezu 4"}
              widthLg={"2600"}
              widthSm={"1200"}
              ratioW={"3"}
              ratioH={"2"}
            />
          </figure>

          <figure className=" w-full md:w-1/2
                              md:-mt-8 mb-8
                              px-4">
            <ImageLazyLoad
              src={"./photos/DSCF7049.jpg"}
              alt={"Backstreets of Nezu 5"}
              widthLg={"2600"}
              widthSm={"1200"}
              ratioW={"3"}
              ratioH={"2"}
            />
          </figure>

        </section>

      </div>
    )
  }
}
export default Day2;