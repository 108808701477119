// https://scotch.io/tutorials/implementing-smooth-scrolling-in-react

import React, { Component } from 'react';
import classNames from 'classnames';
import { Link, Events, animateScroll as scroll } from "react-scroll";

import ButtonChild from './ButtonChild';

class PageNav extends Component {

  // button toggle
  constructor(props) {    
    super(props);
    
    this.state = {
      condition: false,
    }
    
    this.handleClick = this.handleClick.bind(this);
  }

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  componentDidMount() {

    const condition = this.state.condition;
    let changeState = false;

    Events.scrollEvent.register('begin', function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register('end', function () {
      changeState = true;
      console.log("end", condition);
    });

    if (changeState === true) {
      this.setState ({
        condition: !condition
      })
      changeState = false;
    }
    
  }

  handleClick() {
    this.setState({
      condition: !this.state.condition
    })
  }
  
  render() {
    return (

      <nav className={classNames(
        'page-nav flex flex-wrap flex-col justify-between fixed top-0 left-0 bottom-0 z-50 font-sans text-xs',
        {'toggled': this.state.condition},
      )}>

        <Link
            activeClass="is-active"
            to="day-1"
            spy={true}
            smooth={true}
            offset={-70}
            duration= {500}
            title="Jump to day 1"
            toggleClassName={ this.handleClick }
        >1</Link>
        <Link
            activeClass="is-active"
            to="day-2"
            spy={true}
            smooth={true}
            offset={-70}
            duration= {500}
            title="Jump to day 2"
            toggleClassName={ this.handleClick }
        >2</Link>
        <Link
            activeClass="is-active"
            to="day-3"
            spy={true}
            smooth={true}
            offset={-70}
            duration= {500}
            title="Jump to day 3"
            toggleClassName={ this.handleClick }
        >3</Link>
        <Link
            activeClass="is-active"
            to="day-4"
            spy={true}
            smooth={true}
            offset={-70}
            duration= {500}
            title="Jump to day 4"
            toggleClassName={ this.handleClick }
        >4</Link>
        <Link
            activeClass="is-active"
            to="day-5"
            spy={true}
            smooth={true}
            offset={-70}
            duration= {500}
            title="Jump to day 5"
            toggleClassName={ this.handleClick }
        >5</Link>
        <Link
            activeClass="is-active"
            to="day-6"
            spy={true}
            smooth={true}
            offset={-70}
            duration= {500}
            title="Jump to day 6"
            toggleClassName={ this.handleClick }
        >6</Link>
        <Link
            activeClass="is-active"
            to="day-7"
            spy={true}
            smooth={true}
            offset={-70}
            duration= {500}
            title="Jump to day 7"
            toggleClassName={ this.handleClick }
        >7</Link>
        <Link
            activeClass="is-active"
            to="day-8"
            spy={true}
            smooth={true}
            offset={-70}
            duration= {500}
            title="Jump to day 8"
            toggleClassName={ this.handleClick }
        >8</Link>

        <ButtonChild
            className={ this.state.condition ? "button toggled" : "button" }
            toggleClassName={ this.handleClick }
          >
        </ButtonChild>
        
      </nav>

    )
  }
  
}
export default PageNav;