import React, { Component } from 'react';
import Plx from 'react-plx';

class TokyoMetro extends Component {
  render() {

    const sideScroll = [
      {
        start: 'self',
        startOffset: 200,
        duration: 2000,
        easing: "easeOutExpo",
        properties: [
          {
            startValue: 0,
            endValue: -60,
            property: "translateX",
            unit: "%"
          },
          {
            startValue: 0,
            endValue: 1,
            property: "opacity"
          }
        ]
      }
    ];

    return (
      <section
        className="
          flex
          mb-32
          bg-white
        ">

        {/* Maybe replace with a lottie anim? */}
        <figure 
          className="
            tokyo-metro
            relative
            w-full
            
            overflow-hidden
        ">
          <Plx
            className=''
            parallaxData={ sideScroll }
          >
            <img 
              className=""
              src="./illustrations/tokyo-metro.svg"
              alt="An illustration of a salaryman commuter on his phone standing at the door of a moving carriage of the Tokyo metro."
            />
          </Plx>
        </figure>
      </section>
    )
  }
}
export default TokyoMetro;