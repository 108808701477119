import React, { Component } from 'react';
import ImageLazyLoad from './ImageLazyLoad.js';

class PageFooter extends Component {
  render() {
    return (
      <footer className="
        flex flex-wrap justify-end items-end
      ">

        <div className="
          w-full
          mb-8 md:mb-16 lg:mb-32
          px-4 xs:px-8 sm:px-16 md:px-24
          md:col-count-2 xl:col-count-3 xxl:col-count-4 col-gap-lg">
          <p>I have thought a lot about what the purpose of my trip to Tokyo has been and what I may have learned in these few days. Tokyo is clearly a city in a state of constant change. Whether this is even more obvious as it gets ready for the 2020 Olympics, I'm not sure, but the evolving cityscape is captivating.</p>
          <p>It is a city of contrasts, and the interest for me lies in where people meet urban spaces. Inhabitants are tired, glued to their phones and the urban environment is funnelling them through their daily routine.</p>
          <p>Design seems to aspire to alleviate this monotony. Environments are created that are full of personality — the attention to detail apparent from a humble electricity meter, to a row of pot plants where you might least expect it, to the impeccable uniforms of traffic directors present a sense of order and consistency, as well as surprise. The aesthetics of the city, for me at least, go a long way to offset the fact that so many people are living and working together in confined environments.</p>
          <p>Bringing these thoughts back and putting them in the context of everyday design challenges, I think we can all take inspiration from the attention to detail and character applied to everyday solutions. Something that should be an ever present consideration when creating products or services.</p>
        </div>
        
        <figure className=" w-full lg:w-1/2
                            mb-4">
          <ImageLazyLoad
            src={"./drawings/day-13.jpg"}
            alt={"Finnair ---> Oslo"}
            widthLg={"1600"}
            widthSm={"800"}
            ratioW={"624"}
            ratioH={"687"}
          />
        </figure>
        
        <aside className="flex-wrap md:flex-no-wrap
                          w-full lg:w-1/2
                          p-4">

          <p className="mb-8">
            Cobbled together haphazardly during eight days in Tokyo (+ <em>some</em> extra) with a combination of Netlify, React, Tailwind CSS, <a href="https://www.grillitype.com/">Grilli Type</a>, Adobe Illustrator, iPad Pro, lots of walking and Suica swiping <span>&rarr;  </span>
            <a href="https://willhindson.com" title="willhindson.com">willhindson.com</a>  <span>&rarr;  </span>
            <a href="https://instagram.com/wmhindson" title="Instagram">@wmhindson</a>  <span>&rarr;  </span>
            Thanks to <a href="netlife.com" title="netlife.com">Netlife</a>  <span>&rarr;  </span>
            Check out these awesome things: <a href="https://www.almostperfect.jp" title="Almost Perfect">Almost Perfect</a>, <a href="http://www.thetokyoiter.com/" title="The Tokyoiter">The Tokyoiter</a>,  <a href="https://yamanoteyamanote.com/" title="Yamanote Yamanote">Yamanote Yamanote</a>, <a href="https://www.slanted.de/en/product/slanted-31-tokyo/" title="Slanted magazine, Tokyo issue">Slanted magazine</a>. &#127774;
          </p>

          <ul className="flex
                        w-full">
            <li className="w-8 h-8 bg-black border-black border-2 rounded-full"></li>
            <li className="w-8 h-8 bg-grey-darker border-black border-2 rounded-full"></li>
            <li className="w-8 h-8 bg-grey border-black border-2 rounded-full"></li>
            <li className="w-8 h-8 bg-grey-light border-black border-2 rounded-full"></li>
            {/* <li className="w-8 h-8 bg-beige-dark border-black border-2 rounded-full"></li>
            <li className="w-8 h-8 bg-beige border-black border-2 rounded-full"></li> */}
            <li className="w-8 h-8 bg-white border-black border-2 rounded-full"></li>
            <li className="w-8 h-8 bg-beige-light border-black border-2 rounded-full"></li>
            <li className="w-8 h-8 bg-yellow border-black border-2 rounded-full"></li>
            <li className="w-8 h-8 bg-red border-black border-2 rounded-full"></li>
            <li className="w-8 h-8 bg-green border-black border-2 rounded-full"></li>
          </ul>

        </aside>
        
      </footer>
    )
  }
}
export default PageFooter;