// Jimbocho, Harajuku
import React, { Component } from 'react';
import ImageLazyLoad from './ImageLazyLoad.js';
import Plx from 'react-plx';

class Day5 extends Component {
  render() {

    const sectionIntro = [
      {
        start: 'self',
        startOffset: 0,
        duration: 500,
        easing: "easeOutQuad",
        properties: [
          {
            startValue: 300,
            endValue: 0,
            property: "translateY"
          },
          {
            startValue: 0,
            endValue: 1,
            property: "opacity"
          }
        ]
      }
    ];

    return (

      <Plx
        parallaxData={ sectionIntro }
        tagName='div'
        id="day-5"
      >
        
        <section
          className="
            flex flex-wrap
            pt-8 md:pt-16 lg:pt-32
            pb-16 md:pb-32
            px-4 xs:px-8 sm:px-16 md:px-24"
        >

          <header className="
            w-full
            px-4
            mb-8">
            
            <div className="text-lg">
              <h2 className="font-bold">Day 5</h2>
              <em>Wednesday, 09 October</em>
            </div>
            
          </header>

          <div className="w-full
                          mb-8
                          px-4
                          md:col-count-2 xl:col-count-3 col-gap-lg">
            <p>Jimbocho book district. Starting to feel overwhelmed by Tokyo's numbing paradox of choice. Much like previous visits to department stores like Tokyu Hands and Yodobashi, Jimbocho presents so much choice I begin to yearn for constraints.</p>
            <p>With over 200 book shops and given my interest in art and design, it's fun to browse. While on paper (<span role="img" aria-label="skeptical to pun eyes">&#128064;</span>) I should be leaving with a bag full of books; ultimately I leave empty handed.</p>
            <p>I draw some more on the train to Harajuku and roam the perfectly formed streets and alleyways in the area before making a pilgrimage to <a href="https://danielfooddiary.com/2016/04/09/harajukugyozaro/" title="Harajuku Gyozaro">Harajuku Gyozaro</a>, the best (and simultaneously most humble) dumpling shop I have been to.</p>
            <p>The rest of the day is uneventful as I spend a few hours at <a href="https://www.tysons.jp/roastery/" title="The roastery website">The Roastery</a> (by Nozy Coffee) collecting thoughts and working on this very page.</p>
          </div>

          <figure
            className="
              w-full md:w-1/2
              px-8
            ">
            <ImageLazyLoad
              src={"./drawings/day-5-1.jpg"}
              alt={"Train drawings, people falling asleep"}
              widthLg={"2600"}
              widthSm={"1200"}
              ratioW={"440"}
              ratioH={"234"}
            />
          </figure>
          <figure
            className="
              w-full md:w-1/2
              mb-8
              px-8
            ">
            <ImageLazyLoad
              src={"./drawings/day-5-2.jpg"}
              alt={"A photographer and lady in a hat (unrelated)"}
              widthLg={"1800"}
              widthSm={"800"}
              ratioW={"440"}
              ratioH={"189"}
            />
          </figure>
          
          <figure className="w-full px-4 pt-8">
            <ImageLazyLoad
              src={"./photos/DSCF7241.jpg"}
              alt={"An old man wearing a hat carrying an umbrella in the sun"}
              widthLg={"2600"}
              widthSm={"1800"}
              ratioW={"3"}
              ratioH={"2"}
            />
          </figure>

          {/* <div className="w-full md:w-1/2">
            <figure className="w-full px-4">
              <Image
                srcSet="./photos/DSCF7244.jpg?nf_resize=fit&w=1200 1200w,
                        ./photos/DSCF7244.jpg?nf_resize=fit&w=2600 2600w"
                src="./photos/DSCF7244.jpg?nf_resize=fit&w=2600"
                alt="A security guard"
                placeholderColor="#fcfcfc"
              />
            </figure>

            <figure className="w-full px-4">
              <Image
                srcSet="./photos/DSCF7256.jpg?nf_resize=fit&w=1200 1200w,
                        ./photos/DSCF7256.jpg?nf_resize=fit&w=2600 2600w"
                src="./photos/DSCF7256.jpg?nf_resize=fit&w=2600"
                alt="A security guard"
                placeholderColor="#fcfcfc"
              />
            </figure>
          </div>
          
          <figure className="w-full md:w-1/2 px-4">
            <Image
              srcSet="./photos/DSCF7264.jpg?nf_resize=fit&w=1200 1200w,
                      ./photos/DSCF7264.jpg?nf_resize=fit&w=2600 2600w"
              src="./photos/DSCF7264.jpg?nf_resize=fit&w=2600"
              alt="A guy holding an advertising sign for Harajuku poodle"
              placeholderColor="#fcfcfc"
            />
          </figure> */}
          
        </section>
      
      </Plx>
    )
  }
}
export default Day5;