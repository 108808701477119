// Design sight 2021
import React, { Component } from 'react';
import Plx from 'react-plx';
import ImageLazyLoad from './ImageLazyLoad.js';

// import  { ScrollRotate } from 'react-scroll-rotate';

class Day7 extends Component {

  render() {

    const cone1 = [
      {
        start: 'self',
        duration: 2000,
        // easing: "easeInOut",
        properties: [
          {
            startValue: 0,
            endValue: 650,
            property: "translateY"
          },
          {
            startValue: -90,
            endValue: 0,
            property: "rotate",
            unit: "deg"
          }
        ]
      }
    ];
    const cone2 = [
      {
        start: 'self',
        duration: 2000,
        // easing: "easeInOut",
        properties: [
          {
            startValue: 0,
            endValue: 650,
            property: "translateY"
          }
        ]
      }
    ];
    const cone3 = [
      {
        start: 'self',
        duration: 2000,
        // easing: "easeInOut",
        properties: [
          {
            startValue: 0,
            endValue: 650,
            property: "translateY"
          }
        ]
      }
    ];
    const cone4 = [
      {
        start: 'self',
        duration: 2000,
        // easing: "easeInOut",
        properties: [
          {
            startValue: 0,
            endValue: 650,
            property: "translateY"
          }
        ]
      }
    ];

    return (

      <div id="day-7">

        <section
          className="
            day-7
            w-full
            flex flex-wrap items-start">

          <div
            className="
              flex flex-wrap
              pb-16 md:pb-32
              px-4 xs:px-8 sm:px-16 md:px-24
              overflow-hidden"
          >
          
            <header className=" flex flex-wrap
                                w-full">
              <div className="w-full px-4">
                <div className="mb-16 text-lg">
                  <h2 className="font-bold">Day 7 </h2>
                  <em>Friday, 11 October</em>
                </div>
              </div>
            </header>

            <figure className="w-1/2 lg:w-1/4 px-8 mb-4">
              <ImageLazyLoad
                src={"./drawings/day-7-1.jpg"}
                alt={"Train drawings, people falling asleep"}
                widthLg={"1000"}
                widthSm={"600"}
                ratioW={"200"}
                ratioH={"259"}
              />
            </figure>

            <figure className="w-1/2 lg:w-1/4 mb-4">
              <ImageLazyLoad
                src={"./drawings/day-7-2.jpg"}
                alt={"Train drawings, people falling asleep"}
                widthLg={"1000"}
                widthSm={"600"}
                ratioW={"264"}
                ratioH={"213"}
              />
            </figure>

            <figure className="w-1/2 lg:w-1/4 mb-4">
              <ImageLazyLoad
                src={"./drawings/day-7-3.jpg"}
                alt={"Train drawings, people falling asleep"}
                widthLg={"1000"}
                widthSm={"600"}
                ratioW={"264"}
                ratioH={"274"}
              />
            </figure>

            <figure className="w-1/2 lg:w-1/4 mb-4">
              <ImageLazyLoad
                src={"./drawings/day-7-4.jpg"}
                alt={"Train drawings, people falling asleep"}
                widthLg={"1000"}
                widthSm={"600"}
                ratioW={"264"}
                ratioH={"227"}
              />
            </figure>

            <div className="w-full
                            pt-8
                            px-4
                            md:col-count-2 lg:col-count-3 col-gap-lg">
              <p>After breakfast at <a href="https://goo.gl/maps/WWAN6LcH9TT7QjnX6" alt="Coffee Nova">Coffee Nova</a> (bread as thick as a brick, in a good way) in Kuramae, I jump on the metro to Akasaka and <a href="http://www.2121designsight.jp/en/" alt="2121 Design Sight Gallery">2121 Design Sight</a> gallery.</p>
              <p>Inside this angular, brutalist building is the <a href="http://www.2121designsight.jp/en/program/insects/" title="Insects: exhibition website">Insects: Models for Design</a> exhibition. The exhibition attempts to break through the common aversion adults have towards insects, and promote the kind of wonder a child might have. By looking in detail at a range of species, it becomes apparent how perfectly evolution has shaped their forms. Symmetry is an obvious trait, alongside incredible complexity and details that are mind-blowing when considering that “insects are the most diverse biological classification. While humans are in a group of one, insects are said to have 3-10 million species”.</p>
              <p>It's not hard to see the parallels and influence on design, and I leave with insights I hadn't really considered before.</p>
            </div>
          </div>
        
        </section>

        <section
          className="w-full">
          
          <div className="
            relative
            overflow-hidden
            w-full
            ">

            <h2 className=" relative flex flex-wrap
                            w-full
                            text-xxxl font-sans uppercase italic break-all text-center tracking-tight
                            z-10
                            ">
              <span className="w-1/3">R</span>
              <span className="w-1/3">o</span>
              <span className="w-1/3">a</span>
              <span className="w-1/3">d</span>
              <span className="w-1/3">w</span>
              <span className="w-1/3">o</span>
              <span className="w-1/3">r</span>
              <span className="w-1/3">k</span>
              <span className="w-1/3">s</span>
            </h2>

            <div className="absolute top-1/6 left-1/6
                            w-1/5 md:w-1/8 lg:w-1/12 xxl:w-1/16 xxxl:w-1/24
                            rotate-15">
              <Plx parallaxData={ cone1 } >
                <img src="./illustrations/cone-red.svg" alt="A red traffic cone" />
              </Plx>
            </div>
            <div className="absolute top-1/2 right-1/4
                            w-1/6 md:w-1/8 lg:w-1/12 xxl:w-1/16 xxxl:w-1/24
                            rotate-neg75">
              <Plx parallaxData={ cone2 } >
                <img src="./illustrations/cone-red.svg" alt="A red traffic cone" />
              </Plx>
            </div>
            <div className="absolute right-1/6 top-1/6
                            w-1/5 md:w-1/8 lg:w-1/12 xxl:w-1/16 xxxl:w-1/24
                            rotate-neg45">
              <Plx parallaxData={ cone3 } >
                <img src="./illustrations/cone-yellow.svg" alt="A yellow traffic cone" />
              </Plx>
            </div>
            <div className="absolute right-1/2 bottom-1/6
                            w-1/5 md:w-1/8 lg:w-1/12 xxl:w-1/16 xxxl:w-1/24
                            rotate-75">
              <Plx parallaxData={ cone4 } >
                <img src="./illustrations/cone-yellow.svg" alt="A yellow traffic cone" />
              </Plx>
            </div>
          </div>

          <figure className="w-full
                          mb-4 xs:mb-8 sm:mb-16 md:mb-24">
            <ImageLazyLoad
              src={"./photos/DSCF7494.jpg"}
              alt={"A traffic director in Ueno"}
              widthLg={"2600"}
              widthSm={"1200"}
              ratioW={"3"}
              ratioH={"2"}
            />
          </figure>

          <div className="
            w-full
            xs:px-8 sm:px-16 md:px-24
            mb-8">

            <div className="w-full 
                            px-4
                            md:col-count-2 lg:col-count-3 col-gap-lg">
              <p>You may think of roadworks as purely inconvenience, but I beg to differ — at least in Japan. I don't know if it's just the novelty factor, but it's all so <em>organised</em>. The system around road maintenance and building sites here is fascinating.</p>
              <p>The city seems to be in constant flux and you can't walk for more than a couple of blocks without running into a construction site of some kind. Colour coordination, uniform consistency all seems to be part of a larger design system that feels like it's trying to inconvience the passer-by as little as possible.</p>
              <p>An observation: the ratio of people standing around <em>on guard</em> to those actively working appears to be almost overcautiously high.</p>
              {/* I will later learn that these turn out to generally be elderly */}
            </div>
          
          </div>

          <div className="flex flex-wrap
                          w-full
                          mb-8
                          px-4 xs:px-8 sm:px-16 md:px-24">
            
            <figure className=" 
              w-1/2
              px-4">
              <ImageLazyLoad
                src={"./photos/DSCF7298.jpg"}
                alt={"A traffic director"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </figure>

            <figure className="
              w-1/2
              px-4">
              <ImageLazyLoad
                src={"./photos/DSCF7309.jpg"}
                alt={"A traffic director"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </figure>
          
          </div>

          <div className="flex flex-wrap
                          w-full
                          mb-16 md:mb-32
                          px-4 xs:px-8 sm:px-16 md:px-24">
        
            <figure className="w-full mb-8 px-4">
              <ImageLazyLoad
                src={"./photos/DSCF7533.jpg"}
                alt={"A traffic director in Ueno"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </figure>
            
            <figure className="
              w-1/2
              px-4">
              <ImageLazyLoad
                src={"./photos/DSCF7123.jpg"}
                alt={"Roadworks 2"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </figure>

            <figure className="
              w-1/2
              px-4">
              <ImageLazyLoad
                src={"./photos/DSCF7164.jpg"}
                alt={"A building site in Shimokitazawa"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </figure>

          </div>
          
          <figure className="
            w-full
            flex items-start justify-end flex-wrap
            object-cover">
            <ImageLazyLoad
              src={"./photos/DSCF7521.jpg"}
              alt={"Roadworks"}
              widthLg={"2600"}
              widthSm={"1200"}
              ratioW={"3"}
              ratioH={"2"}
            />
          </figure>

        </section>

      </div>

    )
  }
}

export default Day7;