// Kamakura - Open mic
import "animate.css/animate.min.css";
import React, { Component } from 'react';
import classNames from 'classnames';

import ImageLazyLoad from './ImageLazyLoad.js';
import Lottie from 'react-lottie';
import octopus from '../animations/octopus.json';

import Plx from 'react-plx';

class Day6 extends Component {

  constructor(props) {
    super(props)

    this.toggleClass= this.toggleClass.bind(this);
    this.state = {
      activeIndex: 0
    }
  }

  toggleClass(index, e) {
    this.setState({ activeIndex: index });
  };

  render() {

    const sectionIntro = [
      {
        start: 'self',
        startOffset: 0,
        duration: 500,
        easing: "easeOutQuad",
        properties: [
          {
            startValue: 1,
            endValue: 1,
            property: "opacity"
          },
          {
            startValue: 200,
            endValue: 0,
            property: "translateY"
          }
        ]
      }
    ];

    const sectionIntroSqueeze = [
      {
        start: 'self',
        startOffset: 0,
        duration: 500,
        easing: "easeInQuad",
        properties: [
          {
            startValue: 1,
            endValue: 1,
            property: "opacity"
          },
          {
            startValue: 50,
            endValue: 0,
            property: "translateY"
          },
          {
            startValue: 0.9,
            endValue: 1,
            property: "scale"
          }
        ]
      }
    ];

    const sectionIntroNoFade = [
      {
        start: 'self',
        startOffset: 200,
        duration: 300,
        // easing: "easeOutQuad",
        properties: [
          {
            startValue: 200,
            endValue: 0,
            property: "translateY"
          }
        ]
      }
    ];

    const utilityBoxIn = [
      {
        start: 'self',
        startOffset: 200,
        duration: 200,
        easing: "easeOutQuad",
        properties: [
          {
            startValue: 40,
            endValue: 0,
            property: "translateY"
          },
          {
            startValue: 0,
            endValue: 1,
            property: "opacity"
          }
        ]
      }
    ];

    const postCardIntro = [
      {
        start: 'self',
        startOffset: 0,
        duration: 0,
        easing: 'easeOutQuad',
        properties: [
          {
            startValue: 1,
            endValue: 1,
            property: "opacity"
          }
        ]
      }
    ];

    const jamSession = [
      {
        start: 'self',
        duration: 600,
        startOffset: -200,
        // easing: "easeOutExpo",
        properties: [
          {
            startValue: 50,
            endValue: -100,
            property: "translateY"
          },
          {
            startValue: 0,
            endValue: 1,
            property: "opacity"
          }
        ]
      }
    ];

    const meOnStage = [
      {
        start: 'self',
        startOffset: '-100',
        // duration: 2000,
        end: '.day-7',
        endOffset: '0',
        easing: "easeInExpo",
        properties: [
          {
            startValue: -100,
            endValue: 0,
            property: "translateY"
          },
          {
            startValue: 15,
            endValue: 0,
            property: "rotate",
            unit: "deg"
          },
          {
            startValue: 0,
            endValue: 1,
            property: "opacity"
          }
        ]
      }
    ];

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: octopus,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    
    // const { parallaxController } = useController();

    return (

      <div
        id="day-6"
        className="pb-32"
      >
      
        <Plx
          parallaxData={ sectionIntroSqueeze }
          className="transform-top-center"
        >

          <section
            className="pb-8">

            <header 
              className="
              flex flex-wrap
              w-full
              px-4 md:px-8
              mb-8 md:mb-16
            ">

              <div className="mb-8 text-lg">
                <h2 className="font-bold">Day 6 </h2>
                <em>Thursday, 10 October</em>
              </div>

              <figure className=" w-full">
                <ImageLazyLoad
                  src={"./photos/DSCF7331.jpg"}
                  alt={"View from hotel in the morning"}
                  widthLg={"2600"}
                  widthSm={"1800"}
                  ratioW={"3"}
                  ratioH={"2"}
                />
              </figure>

            </header>
            
            <div className="
              flex flex-wrap
              w-full
              px-4 md:px-8
            ">

              <div className="
                flex flex-wrap items-start
                w-3/4 md:w-2/5
                mb-8">
                <div>
                  <p>I wake to a bluebird sky and decide to take the train south to the coastal town of Kamakura; the only trip out of Tokyo this week. It's a nice change of pace from city life and I enjoy wandering the streets soaking up the relaxed atmosphere.</p>
                  <p>I spend some time appreciating some unexpected surfing action — maybe the swell is particularly good given the impending typhoon (more on that later).</p>
                </div>

                <figure className="w-full self-end">
                  <ImageLazyLoad
                    src={"./drawings/day-6-2.jpg"}
                    alt={"Train drawings, people falling asleep"}
                    widthLg={"2600"}
                    widthSm={"1200"}
                    ratioW={"525"}
                    ratioH={"368"}
                  />
                </figure>
              </div>
              
              <div className="w-1/4 md:w-1/5
                              flex flex-col justify-between
                              mb-8
                              font-sans text-xl leading-tightest uppercase text-center">
                <span>K</span>
                <span>a</span>
                <span>m</span>
                <span>a</span>
                <span>k</span>
                <span>u</span>
                <span>r</span>
                <span>a</span>
              </div>
  
              <div className="
                flex flex-wrap items-end
                w-full md:w-2/5
                px-8 sm:px-32 md:px-0">
                <figure className="w-full">
                  <ImageLazyLoad
                    src={"./drawings/day-6-1.jpg"}
                    alt={"Drawing of the interior of Seagreen cafe with a couple sitting infront of me"}
                    widthLg={"2600"}
                    widthSm={"1200"}
                    ratioW={"444"}
                    ratioH={"591"}
                  />
                </figure>
              </div>

            </div>
          
          </section>

          <section className="flex flex-wrap
                              px-4
                              mb-8">

            <figure className=" w-full md:w-1/2
                                px-4
                                mb-8 md:mb-0
                                ">
              <ImageLazyLoad
                src={"./photos/DSCF7430.jpg"}
                alt={"A bridge in Kamakura leading to the beach with truck driving over it."}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </figure>

            <figure className=" w-full md:w-1/2
                                px-4
                                ">
              <ImageLazyLoad
                src={"./photos/DSCF7389.jpg"}
                alt={"The beach in Kamakura with Surfer in the background and office chair in foreground"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </figure>
          
          </section>
        
        </Plx>

        <section className="flex flex-wrap
                            pt-8 md:pt-16
                            px-4
                            mb-8
                            bg-beige-light">

          <div className="relative
                          w-full
                          pt-2/3
                          mx-4 md:mx-8 lg:mx-16 xl:mx-24">

            <Plx 
              parallaxData={ postCardIntro }
              tagName={"figure"}
              className={classNames(
                'postcard absolute top-0 left-0 w-2/3 overflow-hidden rounded-xl',
                {'active': this.state.activeIndex === 5},
                
              )}
              onClick={this.toggleClass.bind(this, 5)}
              >
              <ImageLazyLoad
                src={"./photos/DSCF7339.jpg"}
                alt={"A street cleaner in Kamakura"}
                widthLg={"1920"}
                widthSm={"800"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </Plx>
           
            <Plx 
              parallaxData={ postCardIntro }
              tagName={"figure"}
              className={classNames(
                'postcard absolute top-1/15 left-1/15 w-2/3 overflow-hidden rounded-xl',
                {'active': this.state.activeIndex === 4},
                
              )}
              onClick={this.toggleClass.bind(this, 4)}
              >
              <ImageLazyLoad
                src={"./photos/DSCF7414.jpg"}
                alt={"A lady walking through the streets of Kamakura with clothes matching the green colour of the street paint"}
                widthLg={"1920"}
                widthSm={"800"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </Plx>
              
            <Plx 
              parallaxData={ postCardIntro }
              tagName={"figure"}
              className={classNames(
                'postcard absolute top-2/15 left-2/15 w-2/3 overflow-hidden rounded-xl',
                {'active': this.state.activeIndex === 3},
                
              )}
              onClick={this.toggleClass.bind(this, 3)}
              >
              <ImageLazyLoad
                src={"./photos/DSCF7343.jpg"}
                alt={"A fire station in Kamakura"}
                widthLg={"1920"}
                widthSm={"800"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </Plx>

            <Plx 
              parallaxData={ postCardIntro }
              tagName={"figure"}
              className={classNames(
                'postcard absolute top-3/15 left-3/15 w-2/3 overflow-hidden rounded-xl',
                {'active': this.state.activeIndex === 2},
                
              )}
              onClick={this.toggleClass.bind(this, 2)}
              >
              <ImageLazyLoad
                src={"./photos/DSCF7417.jpg"}
                alt={"Funky van in Kamakura 1"}
                widthLg={"1920"}
                widthSm={"800"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </Plx> 

            <Plx 
              parallaxData={ postCardIntro }
              tagName={"figure"}
              className={classNames(
                'postcard absolute top-4/15 left-4/15 w-2/3 overflow-hidden rounded-xl',
                {'active': this.state.activeIndex === 1},
                
              )}
              onClick={this.toggleClass.bind(this, 1)}
              >
              <ImageLazyLoad
                src={"./photos/DSCF7422.jpg"}
                alt={"Funky van in Kamakura 2"}
                widthLg={"1920"}
                widthSm={"800"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </Plx>

            <Plx 
              parallaxData={ postCardIntro }
              tagName={"figure"}
              className={classNames(
                'postcard absolute top-5/15 left-5/15 w-2/3 overflow-hidden rounded-xl',
                {'active': this.state.activeIndex === 0},
                
              )}
              onClick={this.toggleClass.bind(this, 0)}
              >
              <ImageLazyLoad
                src={"./photos/DSCF7425.jpg"}
                alt={"Funky van in Kamakura 3"}
                widthLg={"1920"}
                widthSm={"800"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </Plx>

          </div>
        
        </section>

        <section className="flex flex-wrap
                            px-4
                            mb-8">

          <figure className=" w-full
                              mb-8 px-4">
            <ImageLazyLoad
              src={"./photos/DSCF7426.jpg"}
              alt={"An old lady with walking stick walking on a street in Kamakura"}
              widthLg={"2600"}
              widthSm={"1200"}
              ratioW={"2"}
              ratioH={"3"}
            />
          </figure>

          <figure className="w-1/2 px-4">
            <ImageLazyLoad
              src={"./photos/DSCF7383.jpg"}
              alt={"A surfer on a wave in Kamakura"}
              widthLg={"2600"}
              widthSm={"1200"}
              ratioW={"2"}
              ratioH={"3"}
            />
          </figure>

          <figure className="w-1/2 px-4">
            <ImageLazyLoad
              src={"./photos/DSCF7388.jpg"}
              alt={"A surfer sitting on the beach in Kamakura"}
              widthLg={"2600"}
              widthSm={"1200"}
              ratioW={"2"}
              ratioH={"3"}
            />
          </figure>
          
        </section>
        
        <Plx parallaxData={ sectionIntro }>

          <section className="flex flex-wrap
                              items-start
                              w-full
                              mb-16">
            <figure>
              <ImageLazyLoad
                src={"./photos/DSCF7395.jpg"}
                alt={"A bright yellow street-crossing button in Kamakura"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </figure>
          </section>

          <section className="flex flex-wrap justify-start
                              px-4 sm:px-8 md:px-16 lg:px-24
                              mb-16 md:mb-32
                              overflow-hidden">
            <h2 className="
              w-full
              mb-8
              text-xl xs:text-xxl sm:text-xxxl xl:leading-tighter
              leading-tighter
            ">
              Zoomorphic utility boxes of Tokyo
            </h2>
            <div className="w-full
                            mb-8 lg:mb-16
                            
                            md:col-count-2 lg:col-count-3">
              <p>I don't know if the designers of Japan's many “utility boxes” (including, as far as I can tell: street crossing buttons, electricity meters, wiring junction boxes, fire alarms, post boxes and many others) had a master plan or if it's a just a coincidence then they all seem to be part of the same zoomorphic family.</p>
              <p>In any case I decided to take it upon myself to begin cataloguing the many varieties in a consistent illustration system: here are the preliminary results.</p>
              <p>I love that these most mundane of objects have (surely intentionally) been injected with life – it's as though there is an army of inanimate robot monsters awaiting to be woken by the flick of a switch and roam the streets of Tokyo.</p>
            </div>
            {/* <img className="w-1/2 md:w-1/3 lg:w-1/4 xxl:w-1/6" src="./illustrations/utility-25.svg" alt="Utility 25"/> */}
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-01.svg" alt="Utility 01"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-02.svg" alt="Utility 02"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-03.svg" alt="Utility 03"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-04.svg" alt="Utility 04"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-05.svg" alt="Utility 05"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-06.svg" alt="Utility 06"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-07.svg" alt="Utility 07"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-08.svg" alt="Utility 08"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-09.svg" alt="Utility 09"/></Plx></figure>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6">
              <Plx parallaxData={ utilityBoxIn }>
                <Lottie
                options={defaultOptions}

                isStopped={false}
                isPaused={false}
              /></Plx>
            </div>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-11.svg" alt="Utility 11"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-12.svg" alt="Utility 12"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-13.svg" alt="Utility 13"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-14.svg" alt="Utility 14"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-15.svg" alt="Utility 15"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-16.svg" alt="Utility 16"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-17.svg" alt="Utility 17"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-18.svg" alt="Utility 18"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-19.svg" alt="Utility 19"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-20.svg" alt="Utility 20"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-21.svg" alt="Utility 21"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-22.svg" alt="Utility 22"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-23.svg" alt="Utility 23"/></Plx></figure>
            <figure className="w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6"><Plx parallaxData={ utilityBoxIn }><img src="./illustrations/utility-24.svg" alt="Utility 24"/></Plx></figure>
          </section>
        </Plx>

        <Plx parallaxData={ sectionIntroNoFade }>

          <section
            className="
              flex items-start flex-wrap
              pt-32 md:pt-32 lg:pt-48
              pb-32
              px-4 md:px-8 lg:px-16 xl:px-24
              bg-black
            ">
            
              <Plx
                className='
                  relative
                  w-full 
                  pt-16 lg:pt-32 xl:pt-64
                  z-0'
                parallaxData={ jamSession }
              >
                <svg viewBox="0 -80 640 300" className="absolute top-0 left-0">
                  <path id="curve1" fill="transparent" d="M0,70C0,70,155.4,0,310,0s310,70,310,70"/>
                  <text x="50%" y ="50%">
                    <textPath xlinkHref="#curve1" fill="red" className="text-jam-session font-sans" textAnchor="middle">
                      Jam Session
                    </textPath>
                  </text>
                </svg>
            
            </Plx>
            <div className="  
              w-full z-10
              mb-8 lg:mb-16 px-4
              md:col-count-2 lg:col-count-3
              text-white
            ">
              <p>Undoubtedly a highlight of the trip. Travelling alone, I'm learning a few things this week, and one of these is to <em>say yes</em>.</p>
              <p>It is with this attitude that I stand at the top of the stairs down to the <a href="https://goo.gl/maps/uQy8VgJsPeGb4Ki26" title="Jam session on Google maps">Jam Session</a> basement in Ueno.</p>
              <p>Hearing the muffled sounds of the Beatles' <em>Come Together</em> from below I eventually muster up the courage to make an entrance. I'm greeted by the friendliest group of Japanese Beatles fanatics I have met “so far” and proceed to spend the next 3 hours in their company.</p>
              <p>Within 10 minutes of arriving I'm being called on stage by Tetsubo (Luigi outfit). On the fourth attempt to summon me some time later, I've accepted my fate and join them onstage to perform 3 songs (I have done this before, in a past life). Very rusty but very fun.</p>
            </div>
            <div className="flex items-end justify-end flex-wrap">
              <figure className="w-2/3 px-4 mb-8">
                <ImageLazyLoad
                  src={"./photos/DSCF7442.jpg"}
                  alt={"Tetsubo"}
                  widthLg={"2600"}
                  widthSm={"1200"}
                  ratioW={"3"}
                  ratioH={"2"}
                />
              </figure>
              <figure className="w-1/3 px-4 mb-8">
                <ImageLazyLoad
                  src={"./photos/DSCF7452.jpg"}
                  alt={"Yanagisawa"}
                  widthLg={"2600"}
                  widthSm={"1200"}
                  ratioW={"2"}
                  ratioH={"3"}
                />
              </figure>
              <figure className="flex flex-wrap w-full mb-8 px-4 items-start">
                <figcaption className="w-full md:w-1/2 xl:w-1/4 md:pr-4 mb-8 text-white">
                  <p>This is Mr. Urano &rarr;<br /> and he is a legend. Absolutely belting out Beatles classics like there's no tomorrow (never knows), he is also responsible for me not being able to get <em>Oh Darling!</em> out of my head for the following two days.</p>
                </figcaption>
                <div className="w-full md:w-1/2 xl:w-3/4">
                  <ImageLazyLoad
                    src={"./photos/DSCF7443.jpg"}
                    alt={"Mr. Urano"}
                    widthLg={"2600"}
                    widthSm={"1200"}
                    ratioW={"3"}
                    ratioH={"2"}
                  />
                </div>
              </figure>

              <figure className=" w-full
                                  px-4 mb-16 lg:mb-32">
                <ImageLazyLoad
                  src={"./photos/DSCF7472.jpg"}
                  alt={"A happy salaryman"}
                  widthLg={"2600"}
                  widthSm={"1200"}
                  ratioW={"3"}
                  ratioH={"2"}
                />
              </figure>
              
              <div className="relative
                              w-full
                              flex justify-center">
                <Plx
                  className='
                    relative
                    w-1/2 lg:w-1/2
                    z-10'
                  parallaxData={ meOnStage }
                >
                  <figure className="rounded-xxl overflow-hidden">
                    <ImageLazyLoad
                      src={"./photos/IMG_5544.jpeg"}
                      alt={"Me on stage"}
                      widthLg={"1920"}
                      widthSm={"800"}
                      ratioW={"3"}
                      ratioH={"2"}
                    />
                  </figure>
                </Plx>
                
                <svg
                  viewBox="0 -25 400 200" 
                  className="
                    block z-0
                    absolute
                    top-1/6 left-0"
                  >
                  <style>{'background-color: transparent;'}</style>
                  <path id="curve" fill="transparent" d="M0,0c75,0,75,100,150,100c75,0,75-100,150-100c75,0,75,100,150,100S525,0,600,0"/>
                  <text x="25">
                    <textPath xlinkHref="#curve" fill="white" className="text-sm font-bold lg:font-normal">
                      <tspan fill="transparent">Thanks Kayo</tspan> for <tspan fill="transparent">the photo. Thanks Tetsubo for the ears.</tspan>
                    </textPath>
                  </text>
                </svg>

                <svg
                  viewBox="0 -25 400 200" 
                  className="
                    block z-20
                    absolute
                    top-1/6 left-0"
                  >
                  <style>{'background-color: transparent;'}</style>
                  <path id="curve" fill="transparent" d="M0,0c75,0,75,100,150,100c75,0,75-100,150-100c75,0,75,100,150,100S525,0,600,0"/>
                  <text x="25">
                    <textPath xlinkHref="#curve" fill="white" className="text-sm font-bold lg:font-normal">
                      Thanks Kayo <tspan fill="transparent">for</tspan> the photo. Thanks Tetsubo for the ears.
                    </textPath>
                  </text>
                </svg>
                
              </div>
              
            </div>
          </section>
        
        </Plx>
      
      </div>
    )
  }
}
export default Day6;