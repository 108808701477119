// Teamlab Borderless
import React, { Component } from 'react';
import ImageLazyLoad from './ImageLazyLoad.js';
import Plx from 'react-plx';

class Day3 extends Component {
  
  render() {

    const sectionIntro = [
      {
        start: 'self',
        startOffset: 0,
        duration: 500,
        easing: "easeOutQuad",
        properties: [
          {
            startValue: 300,
            endValue: 0,
            property: "translateY"
          },
          {
            startValue: 0,
            endValue: 1,
            property: "opacity"
          }
        ]
      }
    ];

    const fallingAsleep = [
      {
        start: 'self',
        startOffset: 100,
        duration: 800,
        easing: "easeOutExpo",
        properties: [
          {
            startValue: 60,
            endValue: 0,
            property: "translateX"
          },
          {
            startValue: 0,
            endValue: 1,
            property: "opacity"
          }
        ]
      }
    ];

    return (

      <div id="day-3">
      
        <section
          className="
            flex flex-wrap
            pb-16 md:pb-32
            px-4 xs:px-8 sm:px-16 md:px-24
            overflow-hidden"
        >

          <Plx parallaxData={ sectionIntro }>

            <header className=" w-full
                                px-4
                                mb-8
                                text-lg">
              <h2 className="font-bold">Day 3 </h2>
              <em>Monday, 07 October</em>
            </header>

            <div className="w-full
                            mb-8
                            px-4
                            md:col-count-2 xl:col-count-3 col-gap-lg">
              <p className="">Teamlab Borderless is the objective this morning — I had heard so much about this exhibition in Koto city, and I'm not disappointed.</p>
              <p>There are hordes of tourists, though I suspect the exhibition requires a certain population to actually work effectively.</p>
              <p>Almost every surface in the huge multi-roomed spaced is either covered by projectors or lighting. Some of the exhibitions are interactive; for example stamping on projected flowers on the floor will cause them to lose petals, or you are able to control the environment in the <em>Crystal room</em> (a highlight) by using an accompanying app.</p>
              <p>The technology behind this is quite mind-blowing; it's probably the most impressive installation I have ever seen; it just goes to show what you can do with code, design and experimentation, and makes me only want to explore this more in the day job.</p>
            </div>
          
          </Plx>

          <div className="flex flex-wrap
                          w-full
                          px-4
                          mb-8">
            <figure className="w-full mb-8">
              <ImageLazyLoad
                src={"./photos/DSCF7092.jpg"}
                alt={"Teamlab borderless"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </figure>
            <figure className="w-1/2 mb-4 pr-4">
              <ImageLazyLoad
                src={"./photos/DSCF7098.jpg"}
                alt={"Teamlab borderless"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </figure>
            <figure className="w-1/2 mb-4 pl-4">
              <ImageLazyLoad
                src={"./photos/DSCF7105.jpg"}
                alt={"Teamlab borderless"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"3"}
                ratioH={"2"}
              />
            </figure>
          </div>

          <div className="w-full
                          px-4 md:px-8 lg:px-16 xl:px-32 xxl:px-64 xxxl:px-96
                          mb-8 lg:mb-16">
            <figure className="">
              <ImageLazyLoad
                src={"./drawings/day-3-1.jpg"}
                alt={"Train observational drawings of people on the way to Teamlab borderless"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"572"}
                ratioH={"179"}
              />
            </figure>
          </div>

          <div className="flex flex-wrap
                          xxl:px-24">

            <div className="flex flex-wrap items-start md:justify-center
                            w-full lg:w-1/2
                            mb-4
                            pr-8 lg:pr-16">
              <figure className="w-full md:w-1/2 lg:w-full">
                <ImageLazyLoad
                  src={"./drawings/day-3-2.jpg"}
                  alt={"A drawing of a selfie lady with her shopping, Daikanyama"}
                  widthLg={"2600"}
                  widthSm={"1200"}
                  ratioW={"572"}
                  ratioH={"375"}
                />
              </figure>
              {/* <figure className=" md:w-1/2 lg:w-full xxl:w-1/2 xxl:order-1
                                  px-16 xl:px-24 xxl:pl-0 xxl:pr-8 overlay">
                <Image
                  srcSet="./drawings/day-3-3.jpg?nf_resize=fit&w=1200 1200w,
                          ./drawings/day-3-3.jpg?nf_resize=fit&w=2600 2600w"
                  src="./drawings/day-3-3.jpg?nf_resize=fit&w=2600"
                  alt="A drawing of an architectural detail, Daikanyama"
                  placeholderColor="#fcfcfc"
                />
              </figure> */}
            </div>
            
            <div className="lg:w-1/2
                            px-4
                            mb-16
                            sm:col-count-2 lg:col-count-1 xl:col-count-2 col-gap-lg">
              <p>In the afternoon, I take the metro back from Koto city and head to the Shibuya-Daikanyama area. After an excellent bowl of <a href="https://goo.gl/maps/62q26y8pWxLiQy8s7" title="Afuri ramen">ramen</a> and browsing various fancy boutiques I stop at <a href="https://goo.gl/maps/LLDBrqaD9DZYMug4A" title="Saturdays NYC on Google maps">Saturdays NYC</a> (another fancy boutique) for a coffee and some sketching.</p>
              <p>Immediately catching my attention, I start drawing a lady who ends up spending about <em>forty minutes</em> taking selfies with her stack of shopping. I can't help but feel that this captures this part of town perfectly.</p>
              <p>A moment of instant karma later and I spill my fancy coffee all over the fancy floor and my bag — the universe telling me to stop silently judging people?</p>
            </div>

            <figure className=" w-full
                                md:px-8 lg:px-16 xl:px-32 xxl:px-64
                                mb-16">
              <Plx parallaxData={ fallingAsleep }>
                <ImageLazyLoad
                  src={"./drawings/day-3-4.jpg"}
                  alt={"A drawing of a lady falling asleep on the train"}
                  widthLg={"2600"}
                  widthSm={"1200"}
                  ratioW={"604"}
                  ratioH={"232"}
                />
              </Plx>                    
            </figure>

            <div className="lg:w-1/2
                            px-4
                            mb-16
                            sm:col-count-2 lg:col-count-1 xl:col-count-2 col-gap-lg">
              <p>After an obligatory pit stop at my favourite book store, <a href="https://store.tsite.jp/daikanyama/english/" title="Daikanyama T-Site (Tsutaya books)">Tsutaya</a> for some browsing and a hot ginger drink (still sick), I jump on the metro back to Taito, for a highly anticipated “illustrator meetup” at gallery / artist residency house <a href="https://www.almostperfect.jp/" title="Almost Perfect">Almost Perfect</a>.</p>
              <p>Knowing there are going to be some great illustrators there and feeling generally a bit inadequate at illustrating people, I continue on my quest to not care so much and just practice at improving observational sketching.</p>
              <p>It becomes quickly apparent that drawing someone falling asleep on public transport can be quite tricky, with the slowly-but-surely descending head tilt, jolt upright and repetition (see: above).</p>
            </div>

            <figure className="w-full lg:w-1/2 px-8">
              <ImageLazyLoad
                  src={"./drawings/day-3-5.jpg"}
                  alt={"A drawing of tired people on the train"}
                  widthLg={"2600"}
                  widthSm={"1200"}
                  ratioW={"540"}
                  ratioH={"369"}
              />
            </figure>

          </div>

        </section>
      
      </div>
    )
  }
}
export default Day3;