// Arrival
import React, { Component } from 'react';
import Plx from 'react-plx';
import ImageLazyLoad from './ImageLazyLoad.js';

class Day1 extends Component {
  render() {

    const sectionIntro = [
      {
        start: 'self',
        startOffset: 0,
        duration: 500,
        easing: "easeOutQuad",
        properties: [
          {
            startValue: 300,
            endValue: 0,
            property: "translateY"
          },
          {
            startValue: 0,
            endValue: 1,
            property: "opacity"
          }
        ]
      }
    ];

    const timePasses = [
      {
        start: 'self',
        startOffset: 0,
        duration: 1000,
        easing: 'easeOutExpo',
        properties: [
          {
            startValue: 100,
            endValue: 0,
            property: "translateX"
          },
          {
            startValue: 0,
            endValue: 1,
            property: "opacity"
          },
          {
            startValue: 45,
            endValue: 0,
            property: "rotate",
            unit: "deg"
          }
        ]
      }
    ];

    return (

      <section
        id="day-1"
        className="
          flex flex-wrap
          pb-16 md:pb-32
          px:8 xs:px-16 sm:px-16 md:px-24"
      >
        <Plx
          parallaxData={ sectionIntro }
          tagName='div'
        >
          <header className="w-full lg:w-1/2 p-4 text-lg">
            <h2 className="font-bold">Day 1</h2>
            <em>Saturday, 05 October</em>
          </header>

          <div className="flex flex-wrap mb-16">

            <div className="w-full lg:w-3/4
                            p-4 mb-8
                            sm:col-count-1 md:col-count-2 xxl:col-count-3 col-gap-lg">
              <p>I arrive in Tokyo at 9 a.m. — bleary-eyed after the journey from Oslo and still overcoming sickness from the previous week. It is unexpectedly hot; 28°C, and hotel check-in isn't open until later in the afternoon.</p>
              <p>After dropping off my luggage at the Dormy Inn Ueno Okachimachi Hot Spring(!) I head for some food, and remembering the first rule of finding somewhere to eat in Tokyo, I fall into line at the back of a not-unreasonable queue at a cook-it-yourself <a href="https://www.google.com/maps/place/Gyukatsu+Motomura+Ueno/@35.7095327,139.7730452,18.28z/data=!4m5!3m4!1s0x60188e9e48da5555:0x5aae1122eba9fa2c!8m2!3d35.7104417!4d139.7744068" alt="Gyukatsu Ueno on Google maps">katsu restaurant</a>. Delicious.</p>
              <p>Determined to start working on something creative, and with Ueno park just round the corner I find a spot at a bench and manage to squeeze in one small sketch of some local signage type between bouts of falling asleep. Unable to resist any longer, I'm able to push for an early check in and I collapse in bed until the evening.</p>
              <p>The evening passes in a blur as I hop on the metro to Ginza and catch the last day of <em>The Shape of Sound</em> <a href="http://rcc.recruit.co.jp/g8/exhibition/201908-3/201908-3.html?lang=en" alt="Shape of Sound exhibition">exhibition</a> – a solo exhibition by art director and music aficionado Tatsuya Ariyama, including fascinating microscope photos of record grooves that out of context look like mountainous landscapes.</p>
            </div>

            <figure className="w-full lg:w-1/4
                          px-32 md:px-64 lg:px-8
                          mb-8 md:mb-16 lg:mb-32">
              <ImageLazyLoad
                src={"./drawings/day-1-1.jpg"}
                alt={"Ueno 3153 - Type spotted in Ueno park"}
                widthLg={"1200"}
                widthSm={"600"}
                ratioW={"212"}
                ratioH={"148"}
              />
            </figure>

          </div>

        </Plx>

        <div className="flex justify-between flex-col
                        w-full
                        text-xxl text-center font-bold leading-normal
                        overflow-hidden">
          <Plx parallaxData={ timePasses }><span role="img" aria-label="11 o'clock">&#128346;</span></Plx>
          <Plx parallaxData={ timePasses }><span role="img" aria-label="12 o'clock">&#128347;</span></Plx>
          <Plx parallaxData={ timePasses }><span role="img" aria-label="01 o'clock">&#128336;</span></Plx>
          <Plx parallaxData={ timePasses }><span role="img" aria-label="02 o'clock">&#128337;</span></Plx>
          <Plx parallaxData={ timePasses }><span role="img" aria-label="03 o'clock">&#128338;</span></Plx>
          <Plx parallaxData={ timePasses }><span role="img" aria-label="04 o'clock">&#128339;</span></Plx>
          <Plx parallaxData={ timePasses }><span role="img" aria-label="05 o'clock">&#128340;</span></Plx>
          <Plx parallaxData={ timePasses }><span role="img" aria-label="06 o'clock">&#128341;</span></Plx>
          <Plx parallaxData={ timePasses }><span role="img" aria-label="07 o'clock">&#128342;</span></Plx>
          <Plx parallaxData={ timePasses }><span role="img" aria-label="08 o'clock">&#128343;</span></Plx>
        </div>

      </section>
    )
  }
}
export default Day1;